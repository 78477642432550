@import "../../../Assets/styles/base";
@import "../../../Assets/styles/breakpoints";

.ast_body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ast_main {
  position: relative;
  flex: 1;
  background: var(--background-color);


  .ast__canvasImg {
    position: absolute;
    top: -13vh;
    right: -11.5vw;
    width: 30vw;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ast_topSection {
  @include container();
  // padding-top: 60px;
  margin-bottom: 50px;

  .ast_subHeading {
    margin: 20px 0 0;
  }
}

.ast-heading--text {
  @include container();
  padding-top: 2.8rem;
  padding-left: 1.125rem;
  display: flex;
}

.ast_dateCont {
  display: flex;
  align-self: flex-end;
  width: 300px;
  margin-right: 16px;

  @media (max-width: 56.25rem) {
    align-self: flex-start;
    margin-top: 1rem;
  }
}

.second-legend {
  margin-left: 16px;
}

.ast_dateCont__start {
  margin-left: 16px;
}

.ast_dateCont__end {
  margin-left: 16px;
}

.ast_dateCont__search {
  margin-left: 16px;
  align-self: flex-end;
}

.ast_datePicker {
  background: #e3edf3;
  border: 0;
  width: 142px;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
}

.ast_legendContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 1.125rem;

  .ast_select {
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .ast_rightLegends {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;

    p {
      color: #90a8be;
      font-size: 14px;
      font-weight: 500;
    }

    &__container_blue {
      display: flex;
      align-items: center;

      div {
        background: var(--primary-color);
        height: 28px;
        width: 45px;
        border-radius: 5px;
        margin-right: 15px;
      }
    }

    &__container_green {
      display: flex;
      align-items: center;
      margin-left: 25px;

      div {
        background: #0fcc98;
        height: 28px;
        width: 45px;
        border-radius: 5px;
        margin-right: 15px;
      }
    }
  }
}

.ast_chartBalanceSection {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;

  .ast_chartContainer {
    width: 100%;
    height: 400px;
    border: 1px solid #90a8be6b;
    border-radius: 5px;
    margin-bottom: 1.125rem;
  }

  .ast_balanceAmount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 2.5rem;

    .ast_balanceWrapper__one,
    .ast_balanceWrapper__two {
      p {
        font-size: 38px;
        color: #368fea;
        font-weight: 600;
        line-height: 1.4;
        word-break: break-all;
      }

      h4 {
        font-size: 1.33rem;
        color: #90a8be;
        line-height: 1.5;
        font-weight: 400;
      }
    }

    .ast_balanceWrapper__two {
      margin-top: 1.3rem;
    }
  }
}

@media (min-width: 56.25rem) {
  .ast_chartBalanceSection {
    flex-direction: row;

    .ast_chartContainer {
      flex-basis: 70%;
      max-width: 70%;
      height: 400px;
      border: 1px solid #90a8be6b;
      border-radius: 5px;
    }

    .ast_balanceAmount {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 2.5rem;

      .ast_balanceWrapper__one,
      .ast_balanceWrapper__two {
        p {
          font-size: 38px;
          color: #368fea;
          font-weight: 600;
          line-height: 1.4;
          word-break: break-all;
        }

        h4 {
          font-size: 1.33rem;
          color: #90a8be;
          line-height: 1.5;
          font-weight: 400;
        }
      }

      .ast_balanceWrapper__two {
        margin-top: 1.3rem;
      }
    }
  }
}

.ast_chartBarsWrapper {
  padding: 50px 5%;
  height: 100%;
  display: flex;
}

.ast_tableSection {
  @include container();
  padding-top: 60px;
}

.ast_table {
  margin: 30px 0;

  & .scrollable-table {
    overflow-x: auto;

    table {
      background: #ffffff;
      border: 1px solid #e2e8ec;
      border-collapse: separate;
      border-radius: 5px;
      border-spacing: 0 15px;
      width: 100%;

      & tbody {
        min-width: 900px;
        overflow: auto;
      }
    }
  }

  th {
    color: #90a8be;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;

    &:last-of-type {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:nth-of-type(2) {
      width: 15%;
    }

    &:nth-of-type(5) {
      width: 10%;
    }
  }

  td {
    text-align: center;
  }

  tbody {
    td {
      background: #e3edf3;

      span {
        display: block;
        margin-top: 24.5px;
        margin-bottom: 24.5px;
        border-right: 2px solid #90a8be47;
        font-size: 15px;
        color: var(--primary-color);
        font-weight: 500;
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-of-type(1) {
        span {
          font-weight: 400;
        }
      }
      &:nth-of-type(2) {
        span {
          color: #90a8be;
        }
      }
      &:nth-of-type(3) {
        span {
          color: #368fea;
        }
      }
      &:nth-of-type(4) {
        span {
          color: #90a8be;
        }
      }
      &:nth-of-type(5) {
        span {
          color: var(--primary-color);
          &.ast_row_green {
            color: #0fcc98;
          }
          &.ast_row_red {
            color: #e50c37;
          }
        }
      }

      &:last-of-type {
        span {
          border-right: none;
          color: #0fcc98;
        }
      }
    }
  }
}

.ast_controls {
  display: flex;
  justify-content: flex-end;
}

.ast_controls__filterSection {
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
}

.ast_controls__filterContainer {
  display: flex;
  flex-basis: 89%;
}

.ast_controls__filterSelect {
  flex-basis: 35%;
}

.ast_controls__sortSelect {
  flex-basis: 65%;
  margin-left: 1rem;

  input {
    width: 100%;
    margin-top: 12px;
    border: 0;
    background: #e3edf3;
    // border-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color);
    font-weight: 300;

    &:focus {
      outline: #2684ff solid 1px;
    }
  }
}

.ast_controls__download {
  flex-basis: 9%;
  max-width: 9%;
  margin-top: 12px;
  background: #e3edf3;
  border-radius: 5px;
  display: flex;
  border: 0;
  justify-content: center;
  margin-bottom: 8px;

  img {
    height: 100%;
    width: 40%;
  }
}

@media (max-width: 56.25rem) {
  .ast_chartBarsWrapper {
    padding: 3rem 0;
  }
  .ast_controls__download {
    margin-bottom: 0.5rem;

    img {
      width: 100%;
    }
  }
  .second-legend {
    margin-left: 0;
  }
  .ast_controls__sortSelect {
    margin-left: 0.2rem;
  }
  .ast_controls__filterSection {
    flex-basis: 100%;
  }
  .ast_controls {
    justify-content: flex-start;
  }
  .ast_tableSection {
    padding: 1rem;
  }
  .ast_legendContainer {
    width: 100%;
    flex-direction: column;
  }
  .ast_dateCont__search {
    align-self: flex-start;
    margin-left: 0;
    margin-top: 1rem;
  }
  .ast_dateCont__start {
    margin-left: 0;
  }
  .ast_dateCont {
    align-self: flex-start;
    margin-top: 1rem;
  }
  .ast_select {
    margin-bottom: 0.8rem;
  }
}
