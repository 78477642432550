@import './breakpoints';

@mixin container {
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 900px) and (max-width: 1250px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@mixin padding {
  padding: 0 5%;
}
