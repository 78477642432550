@import '../../Assets/styles/base';

%dropDown-visibility-style {
  .navBar__dropdown,
  .navBar__dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.navBar__dropdown__small {
  min-width: 150px;
}

.navBar__dropdown__large {
  min-width: 200px;
}

%dropDown-style {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #e3edf387;
  padding: 18px;
  height: auto;
  margin: 0;
  top: 65px;
  right: 0;
  z-index: 4;
  visibility: hidden;
  // transition: all 0.5s ease;
  display: none;
  opacity: 0;

  li {
    background: white;
    margin: 0;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 5px 5px 25px #e3edf3;
    justify-content: flex-start;
    list-style-type: none;

    &.active,
    &:hover {
      background: var(--primary-color);

      a {
        color: white;
      }

      button {
        background: var(--primary-color);
        color: white;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: var(--primary-color);
      font-size: 12px;
      text-decoration: none;
    }

    button {
      border: 0;
      font-size: 12px;
      padding: 0;
      background: white;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}

.navBar__dropdown {
  @extend %dropDown-style;
}

.navBar {
  background: var(--navbar-color);
  z-index: 2;

  &__container {
    @include container();
    height: 89px;
    display: flex;
  }

  &__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 150px;
    height: 57px;
    align-self: center;
    background: transparent;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    p {
      font-size: 25px;
      color: white;
      position: relative;
    }
  }

  &__menu {
    display: flex;
    margin-left: 40px;

    ul {
      display: flex;
      // height: 40%;
      // margin: auto 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        list-style-type: none;
        margin: 0 3%;
        position: relative;
        font-size: 12px;
        cursor: pointer;

        &.active {
          &::after {
            content: '';
            display: block;
            width: 100%;
            border-bottom: 2px solid #ffffff;
            position: absolute;
            top: 65%;
            left: 0;
            transition: all 0.5s ease-out;
          }
        }

        &:last-of-type {
          a {
            white-space: nowrap;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(5) {
          position: relative;

          span {
            &:last-of-type {
              margin-left: 7px;
              width: 14px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .navBar__dropdown {
            @extend %dropDown-style;
          }

          &:hover {
            .navBar__dropdown,
            .navBar__dropdown:hover {
              visibility: visible;
              opacity: 1;
              display: block;
            }
          }
        }

        span {
          width: 17px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .navBar__rightSection__avatar:hover {
    // position: relative;
    height: 100%;
    .navBar__dropdown,
    .navBar__dropdown:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }

  &__rightSection {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    &__dropdown {
      // margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;

      span {
        &:first-of-type {
          color: white;
          font-size: 12px;
        }

        &:last-of-type {
          margin-left: 10px;
          margin-right: 5px;
          width: 14px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        @extend %dropDown-visibility-style;
      }
    }

    &__avatar {
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        &:first-of-type {
          height: 39px;
          width: 39px;
          border-radius: 50%;
          overflow: hidden;
        }

        &:last-of-type {
          margin-left: 10px;
          width: 14px;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

