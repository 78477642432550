@import "../../Assets/styles/base";

%dropDown-visibility-style {
  .navBar__dropdown,
  .navBar__dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.navBar__dropdown__small {
  min-width: 150px;
}

.navBar__dropdown__large {
  min-width: 160px;
}

%dropDown-style {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0.2rem;
  height: auto;
  margin: 0;
  top: 21px;
  left: 0;
  z-index: 4;
  visibility: hidden;
  transition: all 0.5s ease;
  //display: none;
  opacity: 0;

  li {
    background: #fff;
    margin: 0;
    padding: 20px;
    justify-content: flex-start;
    list-style-type: none;

    &.active,
    &:hover {
      background: var(--navbar-color);
      a {
        color: var(--navbar-text-color);
      }
      button {
        background: var(--navbar-color);
        color: white;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: var(--regular-button-color);
      font-size: var(--font-size) !important;
      text-decoration: none;
    }

    button {
      border: 0;
      font-size: var(--font-size) !important;
      padding: 0;
      background: white;
      color: var(--regular-button-color);
      cursor: pointer;
    }
  }
}

.navBar__dropdown {
  @extend %dropDown-style;
}

.navBar {
  background: var(--navbar-color);
  z-index: 2;

  &__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 150px;
    height: 57px;
    align-self: center;
    background: transparent;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    p {
      font-size: 25px;
      color: var(--navbar-text-color);
      position: relative;
    }
  }

  &__menu {
    display: flex;
    margin-left: 75px;

    ul {
      display: flex;
      padding-left: 75px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--navbar-text-color);
        list-style-type: none;
        margin: 0 1%;
        position: relative;
        font-size: var(--font-size) !important;
        cursor: pointer;

        &.active {
          &::after {
            content: "";
            display: block;
            width: 100%;
            border-bottom: 2px solid var(--navbar-text-color);
            position: absolute;
            top: 65%;
            left: 0;
            transition: all 0.5s ease-out;
          }
        }

        &:last-of-type {
          a {
            max-width: 6rem;
            overflow-wrap: break-word;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(5) {
          position: relative;

          span {
            &:last-of-type {
              margin-left: 7px;
              width: 14px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .navBar__dropdown {
            @extend %dropDown-style;
          }

          &:hover {
            .navBar__dropdown,
            .navBar__dropdown:hover {
              visibility: visible;
              opacity: 1;
              display: block;
            }
          }
        }

        span {
          width: 17px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        a {
          color: var(--navbar-text-color);
          text-decoration: none;
          max-width: 6rem;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .navBar__rightSection__avatar:hover {
    // position: relative;
    height: 100%;
    .navBar__dropdown,
    .navBar__dropdown:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }

  &__rightSection {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    &__dropdown {
      margin-right: 15px;
      position: relative;
      height: 100%;
      min-width: 150px;

      span {
        &:first-of-type {
          color: var(--navbar-text-color);
          font-size: var(--font-size) !important;
        }

        &:last-of-type {
          margin-left: 10px;
          margin-right: 5px;
          width: 14px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:hover {
        @extend %dropDown-visibility-style;
      }
    }

    &__notification {
      width: 17px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__mobile {
      display: flex;
    }

    &__avatar {
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px;

      span {
        &:first-of-type {
          height: 38px;
          width: 38px;
          object-fit: cover;
          overflow: hidden;

          &:last-of-type {
            margin-left: 10px;
            width: 14px;
            cursor: pointer;
          }

          & img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
          }
        }

        &:last-of-type {
          margin-left: 10px;
          width: 14px;
          cursor: pointer;
        }
      }
    }

    &__username {
      font-size: var(--font-size) !important;
      color: var(--navbar-text-color);
      margin: 0.5rem 0 0 1rem;
    }
  }
}

@media (max-width: 56.25rem) {

  .navBar {
    &__logo {
      width: 75px;
      height: 40px;
    }
    &__menu {
      margin-left: 0;
    }

    &__menu ul {
      display: initial;
      font: normal normal normal 18px/27px Poppins;
      list-style: none;
      text-align: center;
      height: 100%;
      margin: 10% auto 0 auto;
      padding: 0;

      & li {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--navbar-text-color);
        list-style-type: none;
        margin: 1rem 0;
        margin-bottom: 51px;
        position: relative;
        font-size: 18px;
        cursor: pointer;

        & span {
          margin-right: 10.69px;
          width: 22.31px;
        }
      }
    }

    &__rightSection {
      flex-direction: column;
      margin-left: 0;
      margin-top: -0.8rem;

      &__avatar {
        align-items: center;
        height: 40px;

        &_image {
          border-radius: 50%;
          height: 40px;
          max-width: 100%;
        }

        & ul {
          left: 65px;
          top: 54px;
        }
      }

      &__dropdown {
        font-size: 18px;

        span:first-of-type {
          font-size: 18px;
        }
      }

      &__mobile {
        display: flex;
        position: absolute;
        right: 4rem;
        top: -31rem;
      }

      &__notification {
        img {
          display: none;
        }
      }
    }
  }

  .navBar__dropdown__large {
    min-width: 70%;
  }
}
