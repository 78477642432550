@import "../../../../Assets/styles/base";

.benview__sortSelect {
  flex-basis: 65%;

  input {
    width: 98%;
    margin-top: 12px;
    border: 0;
    background: #e3edf3;
    //background: var(--background-color);
    // border-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 20px;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 300;
    align-items: right;
    margin-bottom: 20px;

    &:focus {
      outline: #2684ff solid 1px;
    }
  }
}

.benView__main {
  background: var(--background-color);
  overflow: hidden;
  position: relative;

  &__canvasImg {
    position: absolute;
    top: 0;
    right: -12vw;
    width: 30vw;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    @include container();
    padding-left: 2.5%;
    padding-top: 60px;
  }
}

.benView__main__heading {
  color: var(--page-title-color);
  font-size: 25px;
  font-weight: 500;
  margin-top: 35px;
  // margin: 0;
}

.benView__main__controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  // margin-bottom: 50px;

  &__dropDown {
    width: 10%;
  }

  &-button {
    min-width: 18%;
    width: 200px;

    button {
      width: 100%;
      background: #0055ad;
      background: var(--primary-color);
      border: 0;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        &:nth-of-type(1) {
          width: 5%;
          margin-right: 4%;
          display: flex;

          img {
            height: 100%;
            width: 100%;
          }
        }

        &:nth-of-type(2) {
          font-size: 13px;
          font-weight: 500;
          color: white;
          text-transform: uppercase;
        }
      }
    }
  }
}

.benView__main__cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 500px;
  padding-right: 1rem;
  padding-left: calc(1rem - 2.5%);

  .benView__card {
    height: 310px;
    width: 100%;
    margin-bottom: 40px;
    background: var(--navbar-color);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 230px;

    %ellipse {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      position: absolute;
    }

    &--ellipse-top {
      @extend %ellipse;
      background: #0fcc984d;
      top: -20px;
      left: -45px;
    }
    &--ellipse-bottom {
      @extend %ellipse;
      background: #3796f64d;
      bottom: -50px;
      right: -40px;
    }

    &__image--outer {
      margin-top: 10%;
      border: 1px solid #0fcc983b;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      padding: 3.5%;
    }

    &__image--inner {
      border: 1px solid #3796f6;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      padding: 9%;
    }

    &__image--main {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &--userName {
      font-size: 20px;
      color: var(--navbar-text-color);
      margin-top: 17px;
      margin-bottom: 22px;
      font-weight: 400;
    }

    &__actions {
      width: 100%;
      display: flex;

      button {
        cursor: pointer;

        &:nth-of-type(1) {
          background: #0055ad;
          background: var(--primary-color);
          width: 35%;
          margin-left: 32%;
          margin-right: 5%;
          border-radius: 5px;
          border: 0;
          color: #f7fafc;
          font-weight: 400;
          font-size: 12px;
          padding: 13px 7px;
        }

        &:nth-of-type(2) {
          border: 0;
          width: 13%;
          background: #0055ad33;
          border-radius: 50%;

          img {
            height: 100%;
            width: 50%;
          }
        }
      }
    }
  }
}

@media (min-width: 40rem) {
  .benView__main__cardContainer {
    .benView__card {
      height: 245px;
      width: 265px;
      flex-basis: unset;
    }

    .benView__card__image--outer {
      margin-top: 27px;
      height: 96px;
      width: 95px;
    }

    .benView__card--userName {
      font-size: 12px;
    }
  }
}

@media (min-width: 40rem) and (max-width: 56.25rem) {
  .benView__main__cardContainer {
    .benView__card {
      width: 350px;
    }

    .benView__card__image--outer {
      margin-top: 27px;
      height: 96px;
      width: 95px;
    }

    .benView__card--userName {
      font-size: 12px;
    }
  }
  .benview__sortSelect {
    input {
      width: 100%;
    }
  }
}

@media (max-width: 56.25rem) {
  .benview__sortSelect {
    input {
      width: 93%;
    }
  }
}
