@import "../../../../Assets/styles/base";
@import "../../../../Assets/styles/breakpoints";

.tsh_main {
  position: relative;
  flex: 1;
}

.tsh__canvasImg {
  position: absolute;
  // top: 19vh;
  right: -11vw;
  width: 30vw;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
  }
}

.tsh_page__heading {
  @include container();
  // padding-top: 60px;
}

.tsh_topSection {
  @include container();
}

.tsh_chartSection {
  display: flex;
  padding-top: 30px;

  .tsh_chart {
    // position: relative;
    flex-basis: 400px;
    max-width: 400px;
    height: 400px;
  }
}

.tsh_chart_legend {
  flex: 1;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tsh_legend {
  display: flex;

  .income,
  .expenses {
    display: flex;
    align-items: center;
    div {
      &:first-of-type {
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }

    span {
      color: #90a8be;
      font-size: 15px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .income {
    margin-right: 30px;
    div {
      &:first-of-type {
        background: #0055ad;
      }
    }
  }

  .expenses {
    div {
      &:first-of-type {
        background: #0fcc98;
      }
    }
  }
}

.tsh_balance_heading {
  color: #90a8be;
  font-size: 18px;
  margin-top: 40px;
  font-weight: 400;
}

.tsh_balance_amount {
  color: #368fea;
  font-size: 50px;
  line-height: 1.4;
  font-weight: 600;
}

.tsh-button-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 70px;
  margin-top: 30px;
}

.tsh-button-wrapper {
  flex-basis: 47%;
}

.tsh_bottomSection {
  @include container();
  padding-top: 60px;
}

.tsh_controls {
  display: flex;
  justify-content: space-between;
}

.tsh_controls__accountSelect {
  flex-basis: 18%;
  margin-top: 1rem;
}

.tsh_dateCont {
  display: flex;
  align-self: flex-end;
  width: 300px;
  margin-right: auto;
}

.tsh_dateCont__start {
  margin-left: 16px;
}

.tsh_dateCont__end {
  margin-left: 16px;
}

.tsh_dateCont__search {
  margin-left: 16px;
  align-self: flex-end;
}

.tsh_datePicker {
  background: #e3edf3;
  border: 0;
  width: 142px;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: #0055ad;
  font-size: 15px;
  font-weight: 300;
}

.tsh_controls__filterSection {
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
  height: 48px;
  margin-top: auto;
  margin-bottom: 12px;
}

.tsh_controls__filterSelect {
  flex-basis: 40%;
}

.tsh_controls__sortSelect {
  flex-basis: 40%;
}

.tsh__filterInput {
  flex-basis: 82%;
  max-width: 82%;

  input {
    width: 100%;
    margin-top: 22px;
    border: 0;
    background: #e3edf3;
    border-radius: 5px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 400;
    color: #0055ad;
    font-weight: 300;

    &:focus {
      outline: #2684ff solid 1px;
    }
  }
}

.tsh__filterPassword {
  flex-basis: 100%;
  max-width: 100%;

  input {
    width: 100%;
    margin-top: 12px;
    border: 0;
    background: #e3edf3;
    border-radius: 5px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 400;
    color: #0055ad;
    font-weight: 300;
    border: 1px solid #0055ad;

    &:focus {
      outline: none;
      background: #0055ad;
      color: #e3edf3;
    }
  }
}

.tsh__filterButton {
  flex-basis: 82%;
  max-width: 82%;

  button {
    width: 40%;
    margin-top: 12px;
    border: 0;
    background: #d9ebf7;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #0055ad;
    font-weight: 300;
    cursor: pointer;

    &:focus {
      outline: none;
      background: #0055ad;
      color: #e3edf3;
    }
  }
}

.passText {
  font-size: 16px;
  font-weight: 600;
  color: #0055ad;
}

.tsh_controls__download {
  margin-top: 20px;
  background: #e3edf3;
  border-radius: 5px;
  display: flex;
  border: 0;
  justify-content: center;
  height: 70%;
  width: 70%;

  img {
    height: 100%;
    width: 40%;
  }
}

.tsh_table {
  margin: 30px 0;
  overflow-x: auto;

  table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    background: var(--background-color);
  }

  th {
    color: #90a8be;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 30px;

    // &:last-of-type {
    //   padding-left: 15px;
    //   padding-right: 15px;
    // }
  }

  td {
    // padding-top: 20px;
    // padding-bottom: 20px;
    text-align: center;
  }

  tbody {
    td {
      background: var(--background-color);
      span {
        display: block;
        margin-top: 24.5px;
        margin-bottom: 24.5px;
        border-right: 2px solid #90a8be47;
        font-size: 17px;
        color: #0055ad;
        font-weight: 500;
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-of-type(1) {
        span {
          font-weight: 400;
        }
      }
      &:nth-of-type(2) {
        span {
          // color: #0fcc98;
          font-weight: 500;
        }
      }
      &:nth-of-type(3) {
        span {
          color: #90a8be;
        }
      }

      &:nth-of-type(5) {
        span {
          color: #0055ad;

          &.tsc-text-green {
            color: #0fcc98;
          }
          &.tsc-text-red {
            color: #e50c37;
          }
          &.tsc-text-blue {
            color: #368fea;
          }
        }
      }

      .tsh-row-button {
        display: flex;
        justify-content: space-around;
        padding: 0 10px;
      }
    }
  }
}

@media (max-width: 56.25rem) {
  .tsh_chartSection {
    flex-direction: column;
  }
  .tsh_chart_legend {
    padding-left: 0;
  }
  .tsh-button-container {
    width: 100%;
    height: 180px;
    flex-direction: column;
  }

  .tsh_controls {
    flex-direction: column;
  }

  .tsh_dateCont {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  .tsh_dateCont__start {
    margin-left: 0;
    margin-top: 1rem;
  }

  .tsh_dateCont__end {
    margin-top: 1rem;
  }

  .tsh_controls__accountSelect {
    margin-bottom: 1rem;
    margin-bottom: 0;
  }

  .tsh_datePicker {
    width: 100%;
  }
}
