@import '../../../../Assets/styles/base';

.benCreate__main {
  background: var(--background-color);
  @include container();
  padding-left: 2.5%;
  //margin-top: 50px;
  //margin-bottom: 60px;
}

.benCreate__main__heading {
  color: var(--page-title-color);
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
}

.benCreate__main__container {
  border: 1px solid #90a8be6b;
  border-radius: 5px;
  padding: 3.5% 8% 3.5% 3.5%;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
}

.benCreate__main__form {
  &--topSection {
    background: var(--background-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--bottomSection {
    width: 47.5%;
    margin-top: 40px;
  }

  &--control {
    flex-basis: 47.5%;
    margin-bottom: 30px;
  }

  &--subHeading {
    border-bottom: 1px solid #0055ad;
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 15px;
    display: flex;
    margin-bottom: 30px;
    flex-basis: 47.5%;

    h3 {
      color: #5bceae;
      font-size: 26px;
      font-weight: 500;
      margin-top: auto;
    }
  }

  &--inputHeading {
    color: #0055ad;
    color: var(--primary-color);
    font-size: 17px;
    font-weight: 500;
  }

  label {
    display: flex;
    flex-direction: column;
    color: #0055ad;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    input {
      margin-top: 12px;
      border: 0;
      background: #e3edf3;
      border-radius: 5px;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &--checkbox {
    margin-bottom: 30px;

    label {
      flex-direction: row;
      align-items: center;
      color: #000000af;

      &:disabled {
        cursor: not-allowed;
      }
    }

    input[type='checkbox'] {
      margin-top: 0;
      margin-right: 15px;
      font-size: 16px;
      border: 1px solid #0055ad;
      border: 1px solid var(--primary-color);
      border-radius: 3px;
      padding: 0;
      background: #fff;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &--button-submit {
    display: flex;
    padding-top: 20px;

    button {
      margin-left: auto;
      border: 0;
      background: #0055ad;
      background: var(--primary-color);
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      padding: 17px 40px;
      min-width: 180px;
    }
  }
}

.benCreate__phoneField {
  &__container {
    display: flex;
  }

  &__selectContainer {
    flex-basis: 40%;
  }

  &__inputContainer {
    flex: 1;
    margin-left: 15px;
  }
}

p {
  margin-bottom: 0 !important;
}

@media (max-width: 52.25rem) {
  .benCreate__phoneField {
    &__inputContainer {
      margin-left: 0px;
    }
  }
}
