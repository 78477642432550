@import '../../Assets/styles/base';


.footerSection {
  background: var(--background-color);
  &__container {
    @include container();

    &__wrapper {
      padding-top: 25px;
      padding-bottom: 60px;
      border-top: 1px solid #90a8be85;
      display: flex;
      justify-content: space-between;

      nav {
        ul {
          display: flex;
          padding: 0;

          li {
            list-style: none;
            list-style-type: none;
            padding-right: 50px;

            a {
              text-decoration: none;
              color: #90a8be;
            }
          }
        }
      }

      div {
        &:last-of-type {
          p {
            color: #90a8be;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 56.25rem) {
  .footerSection {
    &__container {
      &__wrapper {
        background: var(--background-color);
        align-items: center;
        flex-direction: column;
        padding: 0.5rem;

        nav ul {
          align-items: center;
          flex-direction: row;

          li a {
            font-size: 0.8rem;
          }
        }

        div {
          &:last-of-type {
            p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
