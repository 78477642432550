@import '../../../Assets/styles/base';

.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__main {
    position: relative;
    flex: 1;

    .canvasOffset {
      position: absolute;
      height: 100%;
      width: 100%;
      //top: -2vh;
      //left: -2vw;
      background: var(--background-color);
      //border-radius: 20px;
      z-index: -1;
    }

    .canvasImg {
      position: absolute;
      top: 10vh;
      right: -12vw;
      width: 30vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}


.mainSection {
  @include container();
  margin-bottom: 55px;

  &__heading {
    margin-top: 90px;

    p {
      &:first-of-type {
        font-size: 50px;
        color: var(--page-title-color);
        font-weight: 600;
        line-height: 70px;
        margin-bottom: 0;
      }

      &:last-of-type {
        font-size: 35px;
        color: var(--page-title-color);
        font-weight: 500;
      }
    }
  }

  & .circle {
    & .shape1{
      background-color: #0FCC98 !important;
      height: 30px;
      width: 30px;
      top: 45px;
    }
  }

  // &__cards {
  //   display: flex;
  //   width: 85%;
  //   margin-top: 50px;
  //   flex-wrap: wrap;
  //   justify-content: space-between;

  //   button {
  //     height: 158px;
  //     flex-basis: 45%;
  //     background: #0055ad;
  //     background: var(--primary-color);
  //     color: white;
  //     border: 0;
  //     box-shadow: 5px 5px 25px #0000002b;
  //     border-radius: 5px;
  //     margin-bottom: 30px;
  //     position: relative;
  //     overflow: hidden;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     cursor: pointer;

  //     &::before {
  //       content: '';
  //       clip-path: circle(50% at 50% 50%);
  //       background: #0055ad;
  //       background: var(--primary-color);
  //       height: 100px;
  //       position: absolute;
  //       width: 100px;
  //       top: -52%;
  //       left: -5%;
  //     }

  //     &::after {
  //       content: '';
  //       clip-path: circle(50% at 50% 50%);
  //       background: #3796f6;
  //       height: 100px;
  //       position: absolute;
  //       width: 100px;
  //       bottom: -52%;
  //       right: -5%;
  //     }

  //     &:first-of-type {
  //       background: #5bceae;
  //     }

  //     &:nth-of-type(2) {
  //       &::before {
  //         background: #0fcc98;
  //       }

  //       &::after {
  //         background: #3796f6;
  //       }
  //     }

  //     &:nth-of-type(3) {
  //       &::before {
  //         background: #3796f6;
  //         top: -52%;
  //         right: -5%;
  //         left: auto;
  //         bottom: auto;
  //       }

  //       &::after {
  //         top: auto;
  //         bottom: -52%;
  //         left: -5%;
  //         right: auto;
  //         background: #0fcc98;
  //       }
  //     }

  //     &:nth-of-type(4) {
  //       &::before {
  //         background: #3796f6;
  //         top: -52%;
  //         right: -5%;
  //         left: auto;
  //         bottom: auto;
  //       }

  //       &::after {
  //         top: auto;
  //         bottom: -52%;
  //         left: -5%;
  //         right: auto;
  //         background: #0fcc98;
  //       }
  //     }

  //     p {
  //       font-size: 20px;
  //       text-align: left;
  //       flex-basis: 65%;
  //       font-weight: 300;
  //     }

  //     span {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;

  //       &:first-of-type {
  //         flex-basis: 7%;
  //         margin-left: 10%;
  //         margin-right: 5%;
  //       }

  //       &:last-of-type {
  //         margin-left: auto;
  //         margin-right: 10%;
  //         flex-basis: 5%;
  //       }

  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }
}

@media (max-width: 56.25rem) {
  .dashboard__main{

    .canvasOffset {
      position: absolute;
      height: 100%;
      width: 100%;
      background: var(--background-color);
      z-index: -1;
    }

    .canvasImg {
      img {
        width: 0;
        height: 0;
      }
    }
}
  .mainSection {
    padding: 0.5rem;
  }
  .mainSection__heading {
    margin-top: 2rem;

    p {
      &:first-of-type {
        font-size: 1.5625rem;

      }

      &:last-of-type {
        font-size: 1.125rem;
      }
    }



  }
  .mainSection__cards {
      flex-direction: column;
      width: 100%;
    }

  .mainSection__cards button {
    padding: 2.5rem;

    p { font-size: 0.90625rem }
  }




}
