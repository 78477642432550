.createCategory__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.createCategory__main {
  flex: 1;
  position: relative;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }

  &__container {
    max-width: 1100px;
    margin: 0 auto;
  }
}

.createCategory__topControls {
  padding: 4.375rem 0 3.125rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }

  &__headingContainer {
    flex-basis: 25%;
    display: flex;
    align-items: center;
  }

  &__heading {
    color: #0055ad;
    font-size: 25px;
    font-weight: 500;
  }
}

.createCategory__formContainer {
  border: 1px solid #90a8be6b;
  border-radius: 5px;
  width: 100vw;
  margin-bottom: 4.5rem;

  @media (max-width: 56.25rem) {
    width: 91vw;
  }

  &__wrapper {
    padding: 4rem 10% 4rem;
    width: 50vw;

    button {
      width: 40%;
      margin-top: 12px;
      border: 0;
      background: #0055ad;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #e3edf3;
      font-weight: 300;
      cursor: pointer;

      // &:focus {
      //   outline: none;
      //   background:  #0055ad;
      //   color:  #e3edf3;
      // }
    }

    @media (max-width: 56.25rem) {
      padding: 1rem;
      width: 95vw;
    }
  }
}
