.view__cat__body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.view__cat__main {
  flex: 1;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media (max-width: 56.25rem) {
    padding: 1rem;
   }

  &__canvasImg {
    position: absolute;
    top: -3vh;
    right: -20vw;
    width: 30vw;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &--heading {
    color: #0055ad;
    color: var(--primary-color);
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    margin: 50px 0 30px;
  }
}

.view__cat__main--tableContainer {
  overflow-x: auto;
  margin-bottom: 60px;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    border-spacing: 0;
  }

  th {
    text-align: left;
    width: 50%;
    color: #90a8be;
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  tbody {
    td {
      padding: 1.6rem 2.5rem;
      color: #0055ad;
      color: var(--primary-color);

      &:nth-of-type(1) {
        background: #e3edf3;
        font-size: 1.4rem;
      }
      &:nth-of-type(2) {
        background: #dbe7ee;
        font-size: 0.9rem;
        line-height: 1.33;
      }
    }
  }
}
