@import "../../../Assets/styles/base";
@import "../../../Assets/styles/breakpoints";

.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include desktop {
    flex-direction: row;
  }
}

.modSpinner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  @include desktop {
    flex-direction: column !important;
  }
}

.bottomImgLight {
  display: none;
  visibility: hidden;

  @include desktop {
    display: block;
    visibility: visible;
    position: absolute;
    height: 353px;
    bottom: -126px;
    right: -371px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
.bottomImgDark {
  position: absolute;
  height: 169px;
  bottom: -70px;
  left: -310px;

  @include desktop {
    bottom: -130px;
    left: -313px;
    height: 353px;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.leftSection {
  display: flex;
  background: var(--background-color);
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-bottom: 57px;

  @include desktop {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 0;
  }

  .topContainer {
    flex: 1;
    z-index: 3;
    padding: 32px 7.5% 0;

    @include desktop {
      padding: 8vh 5.1vw 0;
    }
  }

  .bottomBar {
    display: none;
    visibility: hidden;

    @include desktop {
      display: block;
      visibility: visible;
      height: 18vh;
      width: 100%;
      // background: #5bceae1a;
    }
  }

  .logo {
    margin-bottom: 50px;
    @include desktop {
      margin-bottom: 10vh;
    }

    span {
      color: var(--page-title-color);
      font-size: 20px;

      @include desktop {
        font-size: 30px;
      }
    }
  }

  .lang {
    float: right;
    font-size: 0.8rem !important;
    width: 150px;
  }

  .selectDropdown {
    height: 5rem !important;
    overflow: scroll;
  }

  .Heading {
    margin-bottom: 20px;

    @include desktop {
      margin-bottom: 3.7vh;
    }

    p {
      &:first-of-type {
        color: #0fcc98;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 18px;

        @include desktop {
          font-size: 30px;
        }
      }

      &:last-of-type {
        color: var(--secondary-color);
        font-size: 11px;
        font-weight: 500;

        @include desktop {
          font-size: 18px;
        }
      }
    }
  }

  .form {
    .inputGroup {
      // width: 100%;
      margin-bottom: 16px;

      @include desktop {
        margin-bottom: 3.74vh;
      }

      &:last-of-type {
        .inputGroup {
          @include desktop {
            margin-bottom: 1vh;
          }
        }
      }

      input {
        display: block;
        width: 100%;
        background: #e3edf3;
        border: 0;
        font-size: 16px;
        padding: 16px 19px;
        // width: 400px;
        color: #0055ad;
        font-weight: 300;
        border-radius: 5px;
        background-clip: padding-box;

        @include desktop {
          font-size: 16px;
          padding: 22px 26px;
        }
      }
    }

    .noPasswordSection {
      display: flex;
      justify-content: flex-end;

      div {
        display: flex;

        &:first-of-type {
          p {
            font-size: 8px;
            color: #2a0032;

            @include desktop {
              font-size: 14px;
            }

            span {
              color: #0fcc98;
            }
          }
        }

        &:last-of-type {
          span,
          a {
            color: #0fcc98;
            font-size: 12px;
            text-decoration: none;

            @include desktop {
              font-size: 14px;
            }
          }
        }
      }
    }

    .loginButton {
      display: block;
      justify-content: flex-end;
      margin: 22px 22px 5px;

      button {
        border: 0;
        background: var(--submit-button-color);
        border-radius: 5px;
        padding: 10px 19px;
        font-size: 12px;
        color: var(--submit-button-text-color);
        cursor: pointer;
        min-width: 100%;

        &:disabled {
          opacity: 0.8;
        }

        @include desktop {
          padding: 12px 30px;
          font-size: 17px;
        }
      }
    }
  }
}

.rightSection {
  background: var(--navbar-color);
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 310px;
  // flex: 1;

  .lang {
    position:absolute;
    right: 5px;
    z-index: 1;
    margin-top: 5px;
    margin-left: 20px;
    font-size: 0.8rem !important;
    width: 180px;

    @media (max-width: 765px) {
      top: -14%;
      display: none;
    }

  }

  @include desktop {
    flex-basis: 50%;
    max-width: 50%;
    height: auto;
  }

  .bannerText {
    margin: auto;
    p {
      color: var(--navbar-text-color);
      font-weight: 500;
      font-size: 19px;

      @include desktop {
        font-size: 35px;
      }

      &:nth-of-type(2) {
        font-size: 32px;
        @include desktop {
          font-size: 60px;
        }
      }

      &:nth-of-type(3) {
        font-weight: 700;
        color: var(--navbar-text-color);
      }
    }
  }

  .topRightCircle {
    position: absolute;
    border-radius: 50%;
    right: -10%;
    border: 2px solid white;
    padding: 1.5%;
    height: 132px;
    width: 132px;
    top: -25%;

    @include desktop {
      height: 220px;
      width: 243px;
    }

    @media (min-width: 1400px) {
      top: -18%;
    }

    @media (min-width: 1600px) {
      top: -14%;
    }

    div {
      background: #0fcc98;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .bottomRightCircle {
    position: absolute;
    border-radius: 50%;
    right: -10%;
    border: 2px solid white;
    padding: 1.5%;
    height: 132px;
    width: 132px;
    bottom: -25%;

    @include desktop {
      height: 220px;
      width: 243px;
    }

    @media (min-width: 1400px) {
      bottom: -18%;
    }

    @media (min-width: 1600px) {
      bottom: -14%;
    }

    div {
      background: #3796f6;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .circle__one {
    position: absolute;
    top: 20%;
    left: 20%;
    border: 2px solid #ffffff4d;
    border-radius: 50%;
    height: 16px;
    width: 16px;

    @include desktop {
      height: 50px;
      width: 50px;
    }
  }
  .circle__two {
    position: absolute;
    top: 40%;
    right: -2%;
    border: 2px solid #ffffff4d;
    border-radius: 50%;
    height: 16px;
    width: 16px;

    @include desktop {
      height: 50px;
      width: 50px;
    }
  }
  .circle__three {
    position: absolute;
    bottom: 23%;
    right: 20%;
    border: 2px solid #ffffff4d;
    border-radius: 50%;
    height: 16px;
    width: 16px;

    @include desktop {
      height: 50px;
      width: 50px;
    }
  }
}

@media (max-width: 56.25rem) {
  .login {
    .leftSection {
      .topContainer {
        padding: 1rem;
      }

      .logo {
        width: 150px;
      }
    }
  }
}
