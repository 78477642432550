.select-search {
    width: 100% !important;
}

.select-search__input {
    color: #0055AD;
    font-size: 15px;
    font-weight: 500;
    padding: 15px 10px;
    min-height: 38px;
    height: 3rem;
    background: #E3EDF3 !important;
}

.select-search__option.is-selected {
    background: #E3EDF3;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #E3EDF3;
    border-color: #2684FF;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #E3EDF3;
    color: #fff;
    border-color: #2684FF;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #E3EDF3;
}
