$break-point-mobile: 576px;
$break-point-tablet: 768px;

@mixin mobile() {
  @media (min-width: $break-point-mobile) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $break-point-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin largedesktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin largerDesktop {
  @media (min-width: 1200px) {
    @content;
  }
}
