@import-normalize;
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import 'Assets/styles/SelectSearch.css';

$primary-color: #0055ad;
$secondary-color: #0fcc98;
$tertiary-color: #e3edf3;

:root {
  --primary-color: #{$primary-color};
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  //font-family: "Poppins", sans-serif;
  font-family: var(--font-style) !important;
}

.primary-size {
  font-size: var(--font-size) !important;
}

.card-color {
  background: var(--navbar-color) !important;
  color: var(--navbar-text-color) !important;
}

.text-note {
  color: #412b7c;
}

.text-blue {
  color: $primary-color !important;
}

.text-green {
  color: $secondary-color !important;
}

.bg-blue {
  background: $primary-color !important;
}

.bg-success {
  background-color: $secondary-color !important;
}

.bg-grey {
  background-color: $tertiary-color
}
.btn-blue {
  background: $primary-color !important;
}

.btn-green {
  background-color: $secondary-color !important;
}

.border-bottom-blue {
  border-bottom: 0.1px solid #0055ad;
}
.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}

.cart-items {
  position: absolute;
  top: -0.3125rem;
  right: -2.8125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: #0fcc98;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

@media (min-width: 992px) {
.navbar-vertical.navbar-expand-lg {
    -ms-flex-flow: column;
    flex-flow: column;
}
}

a.active {
  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 2px solid #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    transition: all 0.5s ease-out;
  }
}

.numberInputField {
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 11px 20px;
  font-size: 16px;
  color: $primary-color;
  font-weight: 300;

  &::placeholder {
    opacity: 1;
  }

  @media (max-width: 56.25rem) {
    width: 100%;
  }

}

#signup-dob .react-datepicker-wrapper {
  width: 100% !important
}
