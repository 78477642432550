.createCategory__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.createCategory__main {
  flex: 1;
  position: relative;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }

  &__canvasImg {
    position: absolute;
    top: 19vh;
    right: -11vw;
    width: 30vw;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    max-width: 1100px;
    margin: 0 auto;
  }
}

.createCategory__topControls {
  padding: 4.375rem 0 3.125rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }

  &__headingContainer {
    flex-basis: 25%;
    display: flex;
    align-items: center;
  }

  &__heading {
    color: #0055ad;
    font-size: 25px;
    font-weight: 500;
  }
}

.createCategory__formContainer {
  border: 1px solid #90a8be6b;
  border-radius: 5px;
  width: 40vw;
  margin-bottom: 4.5rem;

  @media (max-width: 56.25rem) {
    width: 91vw;
  }

  &__wrapper {
    padding: 4rem 10% 4rem;

    @media (max-width: 56.25rem) {
     padding: 1rem;
    }
  }
}
