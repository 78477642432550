.buttonSpinner {
  img {
    width: 17px;
    height: 100%;
    animation: rotate-s-loader 1s linear infinite;
  }
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
