@import '../../../../Assets/styles/base';
@import '../../../../Assets/styles/breakpoints';

.fdt_body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fdt_main {
  flex: 1;
  position: relative;
  background: var(--background-color);
}

.fdt__canvasImg {
  position: absolute;
  right: -11vw;
  width: 30vw;

  img {
    width: 100%;
    height: 100%;
  }
}

.fdt_topSection {
  @include container();
  padding-top: 50px;
  padding-bottom: 60px;

  @media (max-width: 56.25rem) {
    padding: 0.5rem;
  }
}

.fdt_subHeading {
  margin: 20px 0 25px;
}
