@import "../../../Assets/styles/base";

.mainContainer {
  position: relative;
  padding-top: 60px;
  overflow: hidden;
  background: var(--background-color);

  .canvasImg {
    position: absolute;
    top: -10vh;
    right: -12vw;
    width: 30vw;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.topSection {
  @include container();
  // padding-left: 2.5%;
  display: flex;
  justify-content: space-between;

  &__leftSide {
    flex-basis: 25%;

    &__heading {
      margin-bottom: 10px;
      display: flex;
      p {
        font-size: 25px;
        color: var(--primary-color);
        margin-top: 25px;

        @media (max-width: 56.25rem) {
          font-size: 1.125rem;
          margin-top: 25px;

        }
      }
    }

    &__subHeading {
      margin-bottom: 15px;
      span {
        font-size: 18px;
        color: #90a8be;
      }
    }

    &__dropDown {
      margin-bottom: 20px;
      width: 75%;
    }

    .currentBalanceContainer {
      margin-bottom: 30px;

      p {
        &:first-of-type {
          font-size: 18px;
          color: #90a8be;

          @media (max-width: 56.25rem) {
            padding-top: 0.625rem;
          }
        }
        &:last-of-type {
          margin-top: 12px;
          color: #368fea;
          font-size: 23px;
          font-weight: 600;
          word-break: break-all;

          @media (max-width: 56.25rem) {
            padding-bottom: 0.625rem;
          }
        }
      }
    }

    .buttonWrapper {
      display: flex;
      flex-direction: column;

      @media (max-width: 56.25rem) {
        padding-bottom: 1rem;
      }

      button {
        background: var(--primary-color);
        border: 0;
        border-radius: 5px;
        padding: 22px 20px;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        box-shadow: 5px 5px 25px #0000002b;
        cursor: pointer;

        span {
          &:first-of-type {
            width: 15%;
          }

          &:nth-of-type(2) {
            color: var(--submit-button-text-color);
            font-size: 16px;
            margin-left: 7%;
            font-weight: 300;
          }

          &:last-of-type {
            margin-left: auto;
          }

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  &__chartSide {
    flex-basis: 75%;
    max-width: 75%;
    padding-top: 60px;
    padding-left: 7.5%;

    @media (max-width: 56.25rem) {
      max-width: 100%;
      padding: 0;
    }

    &__topContainer {
      display: flex;
      flex-direction: column;

      &__wrapper {
        display: flex;
        width: 35%;
        margin-left: auto;
        justify-content: space-between;
        margin-bottom: 40px;

        .rightSelect {
          flex-basis: 45%;
          z-index: 4;
        }
      }
    }
  }

  .chartSection {
    height: 520px;
    box-shadow: 5px 5px 25px #0000000a;
    border: 1px solid #1149831c;
    border-radius: 5px;
    padding: 5%;
    z-index: 5;
    background: #ffffff;
    z-index: 1;

    @media (max-width: 56.25rem) {
      padding: 0%;
      width: 100%;
      // height: 100%;
    }
  }
}

.bottomSection {
  @include container();
  padding-left: 2.5%;
  margin-top: 85px;
  margin-bottom: 50px;

  .mainHeading {
    color: var(--primary-color);
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .tableControls {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 56.25rem) {
      justify-content: flex-start;
    }

    &__accountSelect {
      flex-basis: 20%;
    }
  }

  .filterSection {
    display: flex;
    flex-basis: 33%;
    justify-content: space-between;

    @media (max-width: 56.25rem) {
      flex-basis: 100%;
    }

    &__filterBar {
      flex-basis: 82%;
      max-width: 82%;

      input {
        width: 100%;
        margin-top: 12px;
        border: 0;
        background: #e3edf3;
        border-radius: 5px;
        padding: 15px 20px;
        font-size: 16px;
        font-weight: 400;
        color: var(--primary-color);
        font-weight: 300;

        &:focus {
          outline: #2684ff solid 1px;
        }
      }
    }

    &__download {
      flex-basis: 15%;
      margin-top: 12px;
      background: #e3edf3;
      border-radius: 5px;
      display: flex;
      border: 0;
      justify-content: center;

      @media (max-width: 56.25rem) {
        margin-bottom: 8px;
      }

      img {
        height: 100%;
        width: 20%;

         @media (max-width: 56.25rem) {
        height: 100%;
        width: 40%;
      }
      }
    }
  }

  .activityTable {
    margin-top: 30px;
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 17px;
          color: var(--primary-color);
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }
        &:nth-of-type(3) {
          span {
            color: #0fcc98;
          }
        }
        &:nth-of-type(4) {
          span {
            border-right: none;
            color: #0fcc98;
          }
        }
      }
    }
  }
}

@media (max-width: 56.25rem) {
  .mainContainer {
    padding: 1rem;
    margin-top: 2.3125rem;
  }

  .topSection {
    flex-direction: column;
  }

  .topSection__leftSide__subHeading span {
    font-size: 0.9375rem;
  }
}
